.Placeholder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  aspect-ratio: 16/10;
}

.ScrollScene,
.ViewportScrollScene {
  outline: 1px dashed rgba(255, 255, 255, 0.33);
  position: relative;
}
.ScrollScene:after,
.ViewportScrollScene:after {
  content: "Output";
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.33);
  color: black;
  font-size: 10px;
  padding: 5px;
}

.ViewportScrollScene:after {
  content: "ViewportScrollScene";
}
