@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide scrollbar for all elements */
*,
*::after,
*::before {
  box-sizing: border-box;
}
/* Firefox */
*::-webkit-scrollbar {
  visibility: hidden;
  display: none;
}
body {
  background-color: black;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  src: local("Poppins Thin"), local("Poppins-Thin"),
    url("./fonts/Poppins-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  src: local("Poppins ExtraLight"), local("Poppins-ExtraLight"),
    url("./fonts/Poppins-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: local("Poppins Light"), local("Poppins-Light"),
    url("./fonts/Poppins-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: local("Poppins Regular"), local("Poppins-Regular"),
    url("./fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: local("Poppins Medium"), local("Poppins-Medium"),
    url("./fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"),
    url("./fonts/Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: local("Poppins Bold"), local("Poppins-Bold"),
    url("./fonts/Poppins-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  src: local("Poppins ExtraBold"), local("Poppins-ExtraBold"),
    url("./fonts/Poppins-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  src: local("Poppins Black"), local("Poppins-Black"),
    url("./fonts/Poppins-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto Mono Regular"), local("RobotoMono-Regular"),
    url("./fonts/RobotoMono-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Mono Medium"), local("RobotoMono-Medium"),
    url("./fonts/RobotoMono-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Mono Bold"), local("RobotoMono-Bold"),
    url("./fonts/RobotoMono-Bold.ttf") format("truetype");
}



@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');


.navbar-items a {
  font-family: 'Rubik', sans-serif;
}


.navbar-logo {
  font-family: 'Poppins', sans-serif; 
}


.navbar-items-enter {
  max-height: 0;
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.navbar-items-enter-active {
  max-height: 500px; 
  opacity: 1;
  transform: translateY(0);
}

.navbar-items-exit {
  max-height: 500px;
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.navbar-items-exit-active {
  max-height: 0;
  opacity: 0;
  transform: translateY(-20px);
}


.navbar-collapsed {
  max-height: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
}


.flip {
  perspective: 1000px;
}

.flip:hover span {
  transform: rotateX(0deg); 
}

.flip span {
  display: block;
  backface-visibility: hidden;
  transition: transform 0.6s;
}

.accordion-button {
  border: 2px solid transparent;
  border-radius: 0.375rem;
  transition: border-color 0.3s ease;
}

.accordion-button:hover {
  border-color: #4fd1c5;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.accordion-content.show {
  max-height: 500px; 
}

.calculator-container {
  max-width: 400px; /* Set maximum width for the calculator */
}

.slider {
  -webkit-appearance: none; /* For webkit browsers */
  width: 100%;
  height: 8px;
  border-radius: 5px;
  background: #ddd; /* Background color for slider */
  outline: none; /* Remove outline */
  transition: background 0.3s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* For webkit browsers */
  appearance: none;
  width: 20px; /* Width of the slider thumb */
  height: 20px; /* Height of the slider thumb */
  border-radius: 50%; /* Circular thumb */
  background: #fff; /* Color of the thumb */
  cursor: pointer; /* Cursor style */
}

.metric {
  gap: 8px; /* Reduced gap between columns */
}

.custom-dropdown {
  width: 40%; /* Make dropdowns equal width */
  padding: 5px; /* Padding inside dropdowns */
  border: 1px solid #ccc; /* Border color */
  border-radius: 5px; /* Rounded edges */
  background: #222; /* Background color */
  color: white; /* Text color */
  appearance: none; /* Remove default arrow */
  transition: background 0.3s; /* Smooth background transition */
}

.custom-dropdown:hover {
  background: #444; /* Darker background on hover */
}

.custom-dropdown:focus {
  outline: none; /* Remove focus outline */
  border: 1px solid #fff; /* Highlight border on focus */
}


.code-terminal-container {
  width: 100%;
  max-width: 600px;
}

.code-terminal-container .header {
  background-color: #1e1e1e;
}

.code-terminal-container .dots span {
  display: inline-block;
}

.code-terminal-container {
  transition: transform 0.3s ease;
}

.code-terminal-container:hover {
  transform: translateY(-5px);
}

.header {
  display: flex;
  align-items: center;
}

.dots span {
  width: 12px;
  height: 12px;
  background-color: #ff5f56; /* Red */
  border-radius: 50%;
}

.dots span:nth-child(2) {
  background-color: #ffbd2e; /* Yellow */
}

.dots span:nth-child(3) {
  background-color: #27c93f; /* Green */
}

.terminal {
  background-color: #1a1a1a;
}

.terminal-content {
  white-space: pre-wrap; /* Preserve whitespace and line breaks */
}

.terminal-line {
  display: flex;
  align-items: center;
}

.terminal-output {
  margin-top: 8px;
}

.code-terminal-container {
  position: relative;
  padding-bottom: 200px; /* Reserve space for the terminal overlay */
}

.terminal-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #1a1a1a;
  padding: 1rem;
  border-top: 1px solid #2d2d2d;
}

/* Rainbow Brackets Styles */
.token.punctuation.bracket-level-0 {
  color: #ff6188; /* Pink */
}

.token.punctuation.bracket-level-1 {
  color: #e4e735; /* Orange */
}

.token.punctuation.bracket-level-2 {
  color: #ff66e8; /* Yellow */
}

.token.punctuation.bracket-level-3 {
  color: #a9dc76; /* Green */
}

.token.punctuation.bracket-level-4 {
  color: #78dce8; /* Cyan */
}

.token.punctuation.bracket-level-5 {
  color: #ab9df2; /* Purple */
}

